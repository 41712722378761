/* global NEWPORTAL */
//todo do not render all slides at once (generate HTML but not insert it into DOM), render only when slide is shown

import inx from 'inx';
import news_data from './data';
import news_image from './image';
import news_security from './security';
import newsUtils from './news';

export default function (
    carouselSelector,
    categories,
    categoryType,
    categoryChosen,
    slideSmallNewsClasses,
    slideBigNewsClasses,
    smallItemBigClasses,
    smallItemSmallClasses,
    additionalMainNewsWrapperClasses,
    bigItemWrapperClasses
) {
    const self = this;

    function htmlToDom(html) {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = html;
        return wrapper.firstElementChild;
    }

    this.slides = [];

    // remove all slides except first
    const carousel = document.querySelector(carouselSelector.trim());
    const carouselSlideWrapper = carousel.querySelector('.wrap-carousel .carousel-inner');
    const allSlides = carouselSlideWrapper.querySelectorAll('.carousel-slide');

    for (let i = 0; i < allSlides.length; i++) {
        const slide = allSlides[i];
        if (!slide.classList.contains('initial_slide')) {
            try {
                carouselSlideWrapper.removeChild(slide)
            } catch (e) {
                console.warn(e);
            }
        }
    }
    // cleanup
    const rowsToCleanup = carousel.querySelectorAll('.small_news .row, .big_news .row');
    for (let i = 0; i < rowsToCleanup.length; i++) {
        rowsToCleanup[i].innerHTML = '';
    }

    var slideHtml = `<div class="carousel-item carousel-slide carousel-slide-wrap"><div class="carousel-indicators"></div></div>`;
    var slideBodyHTML = `
        <div class="col-12 col-xxl-6 carousel_inner col-widget-1">
            <div class="row">
                <div class="main_news_wrapper col-12 col-sm-12 col-lg-12">
                    <div class="widget-1-1 widget-news main-news">
                        <div class="news-overlay">
                            <button class="carousel-control-prev blaze-prev" type="button" data-bs-target="#newsBsCarousel" data-bs-slide="prev">
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next blaze-next" type="button" data-bs-target="#newsBsCarousel" data-bs-slide="next">
                                <span class="visually-hidden">Next</span>
                            </button>
                            <a href="#modal-news" data-bs-toggle="xmodal" data-bs-target="#modal-news" rel="nofollow">
                                <div class="news-logo">
                                    <img alt="" height="50" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                                </div>
                                <div class="news-content">
                                    <h4></h4>
                                </div>
                            </a>
                            <div class="news-select" style="display:none">
                                <ul class="main_news_categories"></ul>
                                <div class="news-more">
                                    <div data-bsx-toggle="dropdown">
                                        +<span class="cat-count">0</span>
                                        <span class="ico ipi-bar"></span>
                                        <ul class="more_news_categories" role="menu"></ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="small_news">
                    <div class="row"><!-- newshere --></div>
                </div>
            </div>
        </div>`;

    var bigNewsHTML = `
        <div class="big_news col-12 col-xxl-6 d-none d-xxxl-block">
            <div class="row"><!-- news for big screens here --></div>
        </div>`;

    // This method should be called at each slide creation
    this.addSlide = function (slideNo, mainNews, smallNews, bigNews) {
        let slide;
        if (slideNo === 0) {
            slide = carousel.querySelector(`.carousel-slide-0`);
        } else {
            slide = htmlToDom(slideHtml);
        }

        slide.classList.add(`carousel-slide-${slideNo}`);
        if (slideNo === 0) {
            slide.classList.add('active');
        }

        this.slides[slideNo] = {
            bigNews,
            el: slide,
            mainNews,
            rendered: false,
            smallNews
        };

        carousel.querySelector('.wrap-carousel .carousel-inner').append(slide);

        if (slideNo === 0) {
            //remove old indicators
            document.querySelector('.carousel-indicators').innerHTML = '';
        }
    };

    this.renderSlide = function (slideNo) {
        // console.log('renderSlide: '+slideNo);
        self.slideNo = slideNo;

        if (!this.slides[slideNo]) {
            return;
        }

        if (this.slides[slideNo].rendered) {
            return;
        }

        self._createSlideHtml(slideNo);

        self._addMainNewsToCarousel(this.slides[slideNo].mainNews);
        self._addCarouselSmallItems(this.slides[slideNo].smallNews);
        self._addCarouselBigItems(this.slides[slideNo].bigNews);

        this.slides[slideNo].rendered = true;
    };

    // "private" helper methods
    this._createSlideHtml = function (slideNo) {
        if (slideNo === 0) {
            return;
        }
        const
            bigNews = htmlToDom(bigNewsHTML),
            news = htmlToDom(slideBodyHTML);

        news.querySelector('.small_news').classList.add(self.slideSmallNewsClasses.trim().split(' '));
        bigNews.classList.add(self.slideBigNewsClasses.trim().split(' '));

        const div = document.createElement('div');
        div.classList.add('d-flex')
        div.append(news, bigNews);

        this.slides[this.slideNo].el.append(div);
    };

    this._addMainNewsToCarousel = function (newsItem) {
        if (!newsItem || !newsItem.title) {
            return;
        }

        const mainNewsEl = this.slides[this.slideNo].el.querySelector(".main-news");
        // for first and second slide preload news image immediately
        let selStyle = '';

        mainNewsEl.setAttribute('style', selStyle);
        mainNewsEl.setAttribute('data-bg_img', news_image.getBgImgUrl(newsItem, self.mainItemImgDim.width, self.mainItemImgDim.height));
        mainNewsEl.setAttribute('data-bg_mob', news_image.getBgImgUrl(newsItem, 300, 300));

        news_data.addDataToNewsLink(mainNewsEl.parentNode, newsItem);
        mainNewsEl.querySelector('h4').textContent = newsItem.title;

        const newsItemSource = newsUtils.getNewsSource(self.carouselSelector.replace('#', ''), newsItem);


        mainNewsEl.querySelector('img').setAttribute('src', NEWPORTAL.news_conf.logo_path + newsItemSource.logo);
        mainNewsEl.querySelector('img').setAttribute('alt', newsItemSource.name);

        // Categories are the same on every slide so render on first slide and copy to other slides for performance
        if (self.slideNo === 0) {
            self._setCategories();
        }
        else {
            //todo probably need to use .news-select instead of .main_news_categories so more categories are copied also
            var categoriesHtml = document.querySelector(self.carouselSelector + ' .carousel-slide-0 .main_news_categories').cloneNode(true);
            if (categoriesHtml) {
                mainNewsEl.querySelector('.main_news_categories').innerHTML = categoriesHtml.innerHTML;
                mainNewsEl.querySelector('.news-select').style.display = 'block';
            } else {
                mainNewsEl.querySelector('.main_news_categories').style.display = 'none';
            }
        }

        if (self.additionalMainNewsWrapperClasses) {
            //add class to .main_news_wrapper
            mainNewsEl.parentNode.classList.add(self.additionalMainNewsWrapperClasses);
        }
    };

    this._setCategories = function () {
        var selector = self.carouselSelector + ' .carousel-slide-' + self.slideNo + ' .main-news';

        var selectorEl = document.querySelector(selector);

        if (!selectorEl.querySelector('.news_category_link')) { //on page load
            var newsCategoryLinks = [];

            //add TOP/ALL category
            var categoryId = self.categoryType === 'category' ? news_data.categoryTop : news_data.categoryAll;
            var topLiClass = self.categoryChosen && self.categoryChosen !== categoryId ? '' : 'active';
            var topCategoryTitle = self.categoryType === 'category' ? inx.i18n.top : inx.i18n.all;
            newsCategoryLinks.push('<li class="' + topLiClass + '"><a class="news_category_link" data-categorytype="' + self.categoryType + '" data-filtercategory="' + categoryId + '">' + topCategoryTitle + '</a></li>');
            for (var i = 0; i < self.categories.length; i++) {
                var categoryName = categories[i].name;
                var filterCategory = categories[i].id;
                if (self.categoryType === "category") {
                    categoryName = inx.i18n['news_category_' + categoryName]; //try to get translation
                    if (typeof categoryName === "undefined") {
                        categoryName = categories[i].name;
                    }
                } else if (self.categoryType === "source") {
                    filterCategory = categories[i].sourceName
                }
                newsCategoryLinks.push('<li><a class="news_category_link" data-categorytype="' + self.categoryType + '" data-filtercategory="' + filterCategory + '">' + categoryName + '</a></li>');
            }
            selectorEl.querySelector('.main_news_categories').innerHTML = newsCategoryLinks.join('');
            if (categories.length > 0) {

                selectorEl.querySelector('.main_news_categories').style.display = 'block';
                selectorEl.querySelector('.news-select').style.display = 'block';
            }
            this.checkIfCategoriesFits(self.carouselSelector + ' .carousel-slide-0 ');
        } else { //on category change
            //todo move to separate function
            const categoryChosen = document.querySelector(selector).querySelectorAll('.news_category_link[data-filtercategory="' + self.categoryChosen + '"]');

            const activeEl = document.querySelector(selector).querySelectorAll('.active');
            for (let i = 0; i < activeEl.length; i++) {
                activeEl[i].classList.remove('active');
            }

            for (let i = 0; i < categoryChosen.length; i++) {
                const categoryWrapper = categoryChosen[i].parentNode;
                categoryWrapper.classList.add('active');
            }
        }
    };

    this.checkIfCategoriesFits = function (slideToUpdateSelector) {
        var totalWidth = 0;
        var catMenu = document.querySelector(slideToUpdateSelector + " .main_news_categories");
        if (!catMenu) {
            return false;
        }

        var menuWidth = Math.round(catMenu.offsetWidth);

        var catMenuParent = catMenu.parentNode;

        //Find total width of each category menu element
        const liElements = catMenu.querySelectorAll("li");
        for (var i = 0, len = liElements.length; i < len; i++) {
            //Count width of each li element
            totalWidth += Math.round(liElements[i].offsetWidth);
            //Check if total li element width is bigger than available space
            if (totalWidth <= menuWidth) {
                catMenuParent.querySelector(".news-more").style.display = "none";
            } else if (totalWidth > menuWidth) {
                //li elements which didn't fit
                //get next elements + add current inside more button
                for (var j = i; j < liElements.length; j++) {
                    const el = liElements[j].cloneNode(true);
                    //Append li elements to dropdown
                    catMenuParent.querySelector(".news-more ul").append(el);
                }

                //Enable dropdown
                catMenuParent.querySelector(".news-more").style.display = "block";
                //Add number of elements in dropdown
                catMenuParent.querySelector(".cat-count").textContent = liElements.length - i;
                //Abort loop
                return false;
            }
        }
    };

    this._htmlToDom = function (html) {
        var wrapper = document.createElement('div');
        wrapper.innerHTML = html;
        return wrapper.firstChild;
    };

    this._addCarouselSmallItems = function (newsItems) {
        var smallItemSelector = self.carouselSelector + ' .carousel-slide-' + self.slideNo + ' .small_news .row';
        for (var i = 0; i < newsItems.length; i++) {
            var currNews = newsItems[i];
            if (!currNews || !currNews.title) {
                break;
            }

            //for first slide load news image immediately
            //for other slides we set image url to bg_img data attribute and load image only when slide is opened (done in newsCarouselInit)
            //todo done already elsewehere
            var bgStyle = '';
            //var bgStyle = self.slideNo === 1 ? news_image.getBgWithImg(currNews, self.smalItemImgDim.width, self.smalItemImgDim.height) : '';

            //first 4 items have one set of classes but last 2 have different set of classes
            var containerClass = i > 3 ? self.smallItemSmallClasses : self.smallItemBigClasses;
            var html = '<div class="' + containerClass + '">' +
                '<div class="widget-news widget-1-2">' +
                '<a href="#modal-news" title="' + news_security.toHtmlEntities(currNews.title) + '" data-bs-toggle="xmodal" data-bs-target="#modal-news" rel="nofollow" >' +
                '<div class="header-s" data-bg_img="' + news_image.getBgImgUrl(currNews, self.smallItemDefaultHighRes.width, self.smallItemDefaultHighRes.height) + '" ' +
                'data-bg_mob="' + news_image.getBgImgUrl(currNews, self.bigItem.width, self.bigItem.height) + '" style="' + bgStyle + '"></div>' +
                '<span class="h6" title="' + news_security.toHtmlEntities(currNews.title) + '">' + news_security.toHtmlEntities(currNews.title) +
                '</span>' +
                '</a>' +
                '</div>' +
                '</div>';

            var widgetItem = this._htmlToDom(html);
            widgetItem = news_data.addDataToNewsLink(widgetItem, currNews);
            document.querySelector(smallItemSelector).append(widgetItem);
        }
    };
    //items for wider screens
    this._addCarouselBigItems = function (newsItems) {
        var bigNewsEl = this.slides[this.slideNo].el.querySelector(".big_news .row");

        for (var i = 0; i < newsItems.length; i++) {
            var currNews = newsItems[i];

            //for first slide load news image immediately
            //for other slides we set image url to bg_img data attribute and load image only when slide is opened (done in newsCarouselInit)
            var bgStyle = self.slideNo === 1 ? news_image.getBgWithImg(currNews, 248, 170) : '';

            var html = '<div class="' + self.bigItemWrapperClasses + '">' +
                '<div class="widget-news widget-2-4">' +
                '<a href="#modal-news" data-bs-toggle="xmodal" data-bs-target="#modal-news" rel="nofollow" >' +
                '<div class="header-l" data-bg_img="' + news_image.getBgImgUrl(currNews, 248, 170) + '" style="' + bgStyle + '"></div>' +
                '<span class="h5" title="' + news_security.toHtmlEntities(currNews.title) + '">' + news_security.toHtmlEntities(currNews.title) + ' </span>' +
                '<p>' + news_security.toHtmlEntities(currNews.shortDescription) +
                '</p>' +
                '</a>' +
                '</div>' +
                '</div>';

            var widgetItem = this._htmlToDom(html);
            widgetItem = news_data.addDataToNewsLink(widgetItem, currNews);
            bigNewsEl.append(widgetItem);
        }
    };

    // Initialize the object here
    (() => {

        // Get dimensions needed for current viewport size for main news item and small news item images
        const viewPortWidth = window.innerWidth;

        self.carouselSelector = carouselSelector;
        self.categories = categories;
        self.categoryType = categoryType;
        self.categoryChosen = categoryChosen;
        self.slideSmallNewsClasses = slideSmallNewsClasses;
        self.slideBigNewsClasses = slideBigNewsClasses;
        self.smallItemSmallClasses = smallItemSmallClasses;
        self.smallItemBigClasses = smallItemBigClasses;
        self.additionalMainNewsWrapperClasses = additionalMainNewsWrapperClasses;
        self.bigItemWrapperClasses = bigItemWrapperClasses;
        //todo remove from news item genration as there is now low dpi mobile devices
        //self.smallItemDefaultMobile = { 'width': 140, 'height': 100 };
        self.smallItemDefaultHighRes = { 'width': 265, 'height': 100 };
        self.bigItem = { 'width': 248, 'height': 170 };

        if (viewPortWidth < 480) {
            self.smalItemImgDim = self.bigItem; //for high dpi devices show 248x170 instead of 140x100
            self.mainItemImgDim = { 'width': 300, 'height': 300 };
        }
        else {
            self.smalItemImgDim = self.smallItemDefaultHighRes;

            self.mainItemImgDim = { 'width': 540, 'height': 300 };
        }
    })();

    return self;
}
